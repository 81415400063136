<!-- About.vue -->
<template>
    <div id="ServicePage">

      <!-- Page Header Start -->
    <div class="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
            <h1 class="display-1 text-white animated slideInDown">Services</h1>
            <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb text-uppercase mb-0">
                    <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                    <li class="breadcrumb-item text-primary active" aria-current="page">Services</li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- Page Header End -->

      <!-- Facts Start -->
    <div class="container-xxl py-5">
        <div class="container pt-5">
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                        <div class="fact-icon">
                            <img src="/frontend/img/icons/icon-2.png" alt="Icon">
                        </div>
                        <h3 class="mb-3">Design Approach</h3>
                        <p class="mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                        <div class="fact-icon">
                            <img src="/frontend/img/icons/icon-3.png" alt="Icon">
                        </div>
                        <h3 class="mb-3">Innovative Solutions</h3>
                        <p class="mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                        <div class="fact-icon">
                            <img src="/frontend/img/icons/icon-4.png" alt="Icon">
                        </div>
                        <h3 class="mb-3">Project Management</h3>
                        <p class="mb-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Facts End -->

    <!-- Service Start -->
    <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
                <h4 class="section-title">Our Services</h4>
                <h1 class="display-5 mb-4">We Focused On Modern Architecture And Interior Design</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-1.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-5.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">Architecture</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-2.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-6.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">3D Animation</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-3.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-7.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">House Planning</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-4.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-8.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">Interior Design</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-5.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-9.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">Renovation</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item d-flex position-relative text-center h-100">
                        <img class="bg-img" src="/frontend/img/service-6.jpg" alt="">
                        <div class="service-text p-5">
                            <img class="mb-4" src="/frontend/img/icons/icon-10.png" style="width: 64px; height: 64px;" alt="Icon">
                            <h3 class="mb-3">Construction</h3>
                            <p class="mb-4">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet.</p>
                            <a class="btn" href="#"><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->

    <!-- Testimonial Start -->
    <div class="container-xxl py-5">
      <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
              <h4 class="section-title">Happy Clients</h4>
              <h1 class="display-5 mb-4">Lots Of Customers Who Trust Us And Our Services</h1>
          </div>

          <!-- Testimonial Carousel -->
          <div class="testimonial-carousel owl-carousel wow fadeInUp" data-wow-delay="0.1s">
              <!-- Testimonial 1 -->
              <div class="testimonial-item text-center">
                  <img class="img-fluid mb-3" src="/frontend/img/testimonial-1.jpg" alt="Client 1" style="width: 100px; border-radius: 50%;">
                  <p class="fs-5">Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                  <h3>Allena Gomez</h3>
                  <span class="text-primary">Laywer</span>
              </div>

              <!-- Testimonial 2 -->
              <div class="testimonial-item text-center">
                  <img class="img-fluid mb-3" src="/frontend/img/testimonial-2.jpg" alt="Client 2" style="width: 100px; border-radius: 50%;">
                  <p class="fs-5">Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                  <h3>Alex Botha</h3>
                  <span class="text-primary">Engineer</span>
              </div>

              <!-- Testimonial 3 -->
              <div class="testimonial-item text-center">
                  <img class="img-fluid mb-3" src="/frontend/img/testimonial-3.jpg" alt="Client 3" style="width: 100px; border-radius: 50%;">
                  <p class="fs-5">Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.</p>
                  <h3>Kane Willeamson</h3>
                  <span class="text-primary">Architect</span>
              </div>
          </div>
      </div>
    </div>


    <!-- Testimonial End -->

    </div>
  </template>
  
  <script>
  export default {
    name: 'ServicePage',
  };
  </script>

<style scoped>

.fact-item:hover .fact-icon{
  background: lightgreen;
}

.service-item:hover img{
  background: white;
}

</style>
  